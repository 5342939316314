import adyen from "./adyen"
import cart from "./cart"
import checkout from "./checkout"
import claims from "./claims"
import connect from "./connect"
import contents from "./contents"
import customerInfo from "./customerinfo"
import invoices from "./invoices"
import markets from "./markets"
import menus from "./menus"
import orders from "./orders"
import productFilter from "./productFilter"
import products from "./products"
import search from "./search"
import siteSettings from "./siteSettings"
import unifaun from "./unifaun"
import users from "./users"

export type ApiComponentType = { id: string; type: string }
export type Properties = Record<string, any>

export type ApiError = {
  status: number
  code: string
  message: string
  error: string
}

export type ApiLink = {
  title: string
  text: string
  href: string
}

const api = {
  contents,
  connect,
  menus,
  markets,
  orders,
  cart,
  siteSettings,
  checkout,
  users,
  invoices,
  customerInfo,
  claims,
  // Customer specific
  //TODO: How to handle thoose to be decided
  unifaun,
  adyen,
  search,
  productFilter,
  products,
}

export type IgniteApi = typeof api

export default api
