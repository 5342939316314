import { usePageContext } from "@ignite/react/context/pageContext"
import { isSearchBot } from "@ignite/utils/device"
import { SheetsRegistry } from "jss"
import { useEffect } from "react"

import EpiConfig from "../../../config"

type PrerenderProps = {
  sheetsRegistry?: SheetsRegistry
}

const injectCss = (css: string) => {
  const styleTag = document.createElement("style")

  styleTag.setAttribute("type", "text/css")
  styleTag.setAttribute("id", "generated-css")
  styleTag.appendChild(document.createTextNode(css))

  document.head.append(styleTag)
}

const Prerender: React.FC<PrerenderProps> = ({ sheetsRegistry }) => {
  const {
    state: { page },
  } = usePageContext()

  useEffect(() => {
    let prerenderTimerID: number

    if (page && page.data && isSearchBot() && !window.prerenderReady) {
      const pageType = page?.data?.type || "UnknownPage"

      prerenderTimerID = window.setTimeout(() => {
        if (sheetsRegistry) {
          // Serves styles located in CSS Object Model (CSSOM)
          // to search bots (eg. Pre-renders)
          injectCss(sheetsRegistry.toString())
        }

        if (!EpiConfig.prerenderReadyIgnorePageTypes.includes(pageType)) {
          window.prerenderReady = true
        }
      }, EpiConfig.prerenderDefaultTimeout)
    }

    return () => {
      clearTimeout(prerenderTimerID)
    }
  }, [page, sheetsRegistry])

  return null
}

export default Prerender
