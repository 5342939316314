import { BaseContent } from "@ignite/api/contents"
import React, { useEffect, useState } from "react"

import { isItemComponent } from "."
import loadComponent, {
  BaseType,
  COMPONENT_CACHE,
  preloadResources,
} from "./componentLoader"

type FallbackProps = {
  fallback?: React.ReactNode
}

type ComponentRenderProps = FallbackProps & {
  baseType: BaseType
  item: BaseContent | BaseContent[]
}

const renderBlockArray = (child: BaseContent[]) =>
  child.reduce<any[]>((acc, nestedChild, ii) => {
    acc.push(<ComponentRenderer baseType="block" item={nestedChild} key={ii} />)
    return acc
  }, [] as React.ReactNode[])

const ComponentRenderer: React.FC<ComponentRenderProps> = ({
  baseType,
  item,
  fallback,
}) => {
  const isItemArray = Array.isArray(item)
  //we need to wrap the component in an object
  const [{ Component }, setComponent] = useState({
    Component:
      (isItemArray && null) ||
      COMPONENT_CACHE[baseType][(item as BaseContent).type],
  })

  useEffect(() => {
    if (!isItemArray && !Component) {
      const loadAndRenderComponent = async () => {
        await preloadResources(item as BaseContent, baseType)
        const Component = await loadComponent(
          baseType,
          (item as BaseContent).type
        )
        setComponent({
          Component,
        })
      }
      loadAndRenderComponent()
    }
  }, [Component, baseType, isItemArray, item])

  if (!Component && !isItemArray) {
    if (process.env.NODE_ENV !== "production") {
      console.log(
        `ContentRenderer cache does not contain ${
          (item as BaseContent).type
        }. Consider preloading the types in the response`
      )
    }
    return fallback || (null as any)
  }

  if (Array.isArray(item)) {
    return renderBlockArray(item)
  }
  const { properties, id, ...rest } = item

  const componentProps = { ...properties }

  const children = Object.entries(componentProps).reduce<React.ReactNode[]>(
    (acc, [propertyName, child]: [string, BaseContent], i) => {
      let childComponent: React.ReactNode | null = null
      if (
        Array.isArray(child) &&
        child.length > 0 &&
        isItemComponent(child[0])
      ) {
        childComponent = renderBlockArray(child)
        acc = [
          ...acc,
          ...(childComponent instanceof Array ? childComponent : []),
        ]
      } else {
        if (isItemComponent(child)) {
          childComponent = (
            <ComponentRenderer baseType="block" item={child} key={i} />
          )
          acc.push(childComponent)
        }
      }
      if (childComponent) {
        componentProps[propertyName] = childComponent
      }
      return acc
    },
    []
  )

  return (
    <Component _id={id} {...rest} {...componentProps}>
      {children.length > 0 && children}
    </Component>
  )
}

type PageBlockRendererProps = FallbackProps & {
  content: BaseContent | BaseContent[]
}

export const PageRenderer: React.FC<PageBlockRendererProps> = ({
  content,
  fallback,
}) => <ComponentRenderer baseType="page" fallback={fallback} item={content} />

export const BlockRenderer: React.FC<PageBlockRendererProps> = ({
  content,
  fallback,
}) => {
  return (
    <ComponentRenderer baseType="block" fallback={fallback} item={content} />
  )
}
