import { Breakpoints } from "@material-ui/core/styles/createBreakpoints"
import { TypographyOptions } from "@material-ui/core/styles/createTypography"

export const TypographyVariants = [
  "primary-1000",
  "primary-900",
  "primary-800",
  "primary-750",
  "primary-700",
  "primary-650",
  "primary-600",
  "primary-500",
  "primary-450",
  "primary-400",
  "primary-300",
  "primary-200",
  "primary-100",
  "secondary-1000",
  "secondary-900",
  "secondary-800",
  "secondary-750",
  "secondary-700",
  "secondary-650",
  "secondary-600",
  "secondary-500",
  "secondary-450",
  "secondary-400",
  "secondary-300",
  "secondary-200",
  "secondary-100",
] as const

export type TypographyVariantsType = (typeof TypographyVariants)[number]

export const typography = ({ breakpoints }: { breakpoints: Breakpoints }) => ({
  "primary-1000": {
    fontSize: "74px",
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: "-.04em",
    [breakpoints.down("md")]: {
      fontSize: "42px",
      letterSpacing: "-.03em",
    },
  },
  "primary-900": {
    fontSize: "58px",
    lineHeight: "60px",
    fontWeight: 400,
    letterSpacing: "-.03em",
    [breakpoints.down("md")]: {
      fontSize: "36px",
      lineHeight: "40px",
    },
  },
  "primary-800": {
    fontSize: "46px",
    lineHeight: "48px",
    fontWeight: 400,
    letterSpacing: "-.03em",
    [breakpoints.down("md")]: {
      fontSize: "32px",
      lineHeight: "38px",
    },
  },
  "primary-750": {
    fontSize: "42px",
    lineHeight: "44px",
    fontWeight: 400,
    letterSpacing: "-.03em",
    [breakpoints.down("md")]: {
      fontSize: "32px",
      lineHeight: "38px",
    },
  },
  "primary-700": {
    fontSize: "36px",
    lineHeight: "40px",
    fontWeight: 400,
    letterSpacing: "-.03em",
    [breakpoints.down("md")]: {
      fontSize: "32px",
      lineHeight: "38px",
    },
  },
  "primary-650": {
    fontSize: "32px",
    lineHeight: "38px",
    fontWeight: 400,
    letterSpacing: "-.03em",
    [breakpoints.down("md")]: {
      fontSize: "26px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
    },
  },
  "primary-600": {
    fontSize: "26px",
    lineHeight: "32px",
    fontWeight: 400,
    letterSpacing: "-.02em",
  },
  "primary-500": {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: 400,
  },
  "primary-450": {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 400,
  },
  "primary-400": {
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
  },
  "primary-300": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  "primary-200": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
  "primary-100": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    [breakpoints.only("xs")]: {
      fontSize: "10px",
    },
  },
  "secondary-1000": {
    fontSize: "42px",
    lineHeight: "44px",
    fontWeight: 400,
    letterSpacing: "-.04em",
  },
  "secondary-900": {
    fontSize: "36px",
    lineHeight: "40px",
    fontWeight: 400,
    letterSpacing: "-.03em",
  },
  "secondary-800": {
    fontSize: "32px",
    lineHeight: "38px",
    fontWeight: 400,
    letterSpacing: "-.03em",
  },
  "secondary-750": {
    fontSize: "32px",
    lineHeight: "28px",
    fontWeight: 400,
    letterSpacing: "-.03em",
  },
  "secondary-700": {
    fontSize: "32px",
    lineHeight: "38px",
    fontWeight: 400,
    letterSpacing: "-.03em",
  },
  "secondary-650": {
    fontSize: "26px",
    lineHeight: "32px",
    fontWeight: 400,
    letterSpacing: "-.03em",
  },
  "secondary-600": {
    fontSize: "26px",
    lineHeight: "32px",
    fontWeight: 400,
    letterSpacing: "-.02em",
  },
  "secondary-500": {
    fontSize: "22px",
    lineHeight: "30px",
    fontWeight: 400,
  },
  "secondary-450": {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 400,
  },
  "secondary-400": {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  "secondary-300": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  "secondary-200": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
  "secondary-100": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
  },
})

/**
 * Ignite Theme - Typography
 * @param breakpoints
 */
export const createTypography = (
  breakpoints: Breakpoints
): TypographyOptions => ({
  ...typography({ breakpoints }),
})
