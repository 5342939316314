import Box from "@material-ui/core/Box"
import React from "react"

export type MissingComponentProps = {
  type: string
  baseType: string
}

const STRIPE_BACKGROUND =
  "repeating-linear-gradient(45deg,red,red 10px,white 10px,white 20px)"

const MissingComponent: React.FC<MissingComponentProps> = ({
  baseType,
  type,
}) => {
  const baseTypeName = `${baseType[0].toUpperCase()}${baseType.substring(1)}`

  return (
    <Box
      style={{
        background: STRIPE_BACKGROUND,
      }}
      textAlign="center"
      color="red"
      width="100%"
      p={2}
      border="1px solid red"
    >
      <Box
        p={1}
        component="strong"
        style={{
          backgroundColor: "white",
        }}
      >{`${baseTypeName} with type "${type}" not found`}</Box>
    </Box>
  )
}

export default MissingComponent
