import ErrorIcon from "@material-ui/icons/Error"
import React from "react"
import { ErrorBoundary } from "react-error-boundary"

import GlobalErrorFallback from "./GlobalErrorFallback"

type GlobalErrorBoundryProps = {
  errorHandler?: (error: Error, info: { componentStack: string }) => void
  errorImage?: React.ReactElement
}

const GlobalErrorBoundary: React.FC<GlobalErrorBoundryProps> = ({
  children,
  errorHandler,
  errorImage,
}) => {
  const ErrorFallback = () => (
    <GlobalErrorFallback image={errorImage || <ErrorIcon color={"error"} />} />
  )

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
      {children}
    </ErrorBoundary>
  )
}

export default GlobalErrorBoundary
