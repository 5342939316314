import { createTheme, ThemeOptions } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
// eslint-disable-next-line no-restricted-imports
import { Overrides } from "@material-ui/core/styles/overrides"

import colors from "./colors"
import { createTypography } from "./typography"

const initialThemeOptions = {
  fontWeightBold: 600,
  fontWeightBoldSecondary: 700,
}

let themeOverride: (options: ThemeOptions) => ThemeOptions

try {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  themeOverride = require("theme").default
} catch (e) {
  if (process.env.NODE_ENV !== "production") {
    console.warn(
      "No custom theme found: Implement ThemeOverride function and export default in /src/theme.ts"
    )
  }

  themeOverride = (options) => options
}

const themeOverrideFunctionType = typeof themeOverride
if (!themeOverride || themeOverrideFunctionType !== "function") {
  throw Error('theme in /src must export default a "ThemeOverride" function')
}

const breakpoints = createBreakpoints({})
const customTypographyOptions = createTypography(breakpoints)
const overrides: Overrides = {
  App: {
    html: {
      backgroundColor: colors.white.main,
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: colors.backdrop.main,
    },
  },
  MuiButtonBase: {
    root: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: initialThemeOptions.fontWeightBold,
      textAlign: "center",
    },
  },
  MuiButton: {
    root: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: initialThemeOptions.fontWeightBold,
      padding: "16px 36px",
      textTransform: "none",
    },
    contained: {
      "&$disabled": {
        color: colors.white.main,
      },
    },
    containedPrimary: {
      backgroundColor: colors.black.main,
      "&:hover": {
        backgroundColor: colors.black[400],
      },
      "&$disabled": {
        color: colors.white.main,
      },
    },
    containedSecondary: {
      backgroundColor: colors.white.main,
      color: colors.black.main,
      "&:hover": {
        backgroundColor: colors.ui.light,
      },
    },
    containedSizeSmall: {
      fontSize: "16px",
      lineHeight: "24px",
      padding: "12px 24px",
    },
    containedSizeLarge: {
      fontSize: "16px",
      lineHeight: "24px",
      padding: "19px 48px",
    },
    outlined: {
      padding: "16px 36px",
    },
    outlinedPrimary: {
      border: `2px ${colors.black[500]} solid`,
      "&:hover": {
        border: `2px ${colors.black[500]} solid`,
      },
    },
    outlinedSizeSmall: {
      fontSize: "16px",
      lineHeight: "24px",
      padding: "12px 24px",
    },
    outlinedSizeLarge: {
      fontSize: "16px",
      lineHeight: "24px",
      padding: "19px 48px",
    },
    textPrimary: {
      color: colors.black.main,
      "&:hover": {
        color: colors.black.main,
        backgroundColor: "transparent",
      },
      "&$disabled": {
        color: "rgba(0, 0, 0, 0.12)",
        backgroundColor: "transparent",
      },
    },
    textSizeSmall: {
      fontSize: "16px",
    },
    textSizeLarge: {
      fontSize: "16px",
    },
  },
  MuiSelect: {},
  MuiBadge: {},
  MuiChip: {},
  MuiLink: {
    root: {
      textUnderlinePosition: "under",
    },
  },
  MuiAccordion: {
    root: {
      backgroundColor: "transparent",
    },
  },
  MuiAccordionSummary: {
    root: {
      padding: 0,
    },
  },
  MuiAccordionDetails: {
    root: {
      padding: 0,
    },
  },
  MuiFormLabel: {
    root: {
      color: colors.black.main,
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: 14,
    },
  },
  MuiInputLabel: {
    shrink: {
      color: colors.black.main,
      position: "static",
      transform: "translate(0, 0)",
      fontSize: 14,
      marginBottom: -4,
      "&$focused": {
        color: colors.black.main,
      },
    },
  },
  MuiInput: {
    input: {
      height: 48,
      padding: "16px 12px",
      boxSizing: "border-box",
      border: `1px solid #dededc`,
    },
    underline: {
      "&:before": {
        borderBottomColor: colors.grey[650],
      },
      "&:after": {
        borderBottomColor: colors.black[500],
      },
      "&$focused:not($disabled):after": {
        borderBottomColor: colors.black[500],
      },
      "&$disabled:after, &$disabled:before": {
        borderBottom: `1px solid ${colors.grey[650]}`,
      },
      "&:hover:not($disabled):not($error):not($focused):before": {
        borderBottom: `2px solid ${colors.grey[650]}`,
      },
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: 14,
      marginTop: 2,
    },
    contained: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  Dialog: {
    root: {
      margin: 0,
    },
    paper: {
      padding: 32,
      margin: 32,
    },
    content: {
      padding: 0,
    },
    actions: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  TopSectionButton: {},
  Price: {},
  PageHeader: {},
  ProductSection: {},
  ProductCardTag: {},
  ProductPage: {},
  ProductCard: {},
  ContentPageCard: {},
  HighlightCard: {},
  CategoryCarouselBlock: {},
  HeroBlock: {},
  MediaCardBlock: {},
  CarouselThumbnailItem: {},
  HeaderCheckout: {},
  QuantityCounter: {},
  MobileMenuItem: {},
  MobileMenu: {},
  MobileMenuAuthButton: {
    root: {
      marginTop: 16,
      marginBottom: 0,
      marginLeft: 8,
      marginRight: 16,
      width: `calc(100% - 32px)`,
      padding: `16px 0`,
    },
    icon: {
      width: 12,
      marginRight: 12,
    },
  },
  HeaderContainer: {},
  CheckoutBox: {},
  CheckoutCouponListItem: {},
  Marquee: {},
  DividerBlock: {},
  IgniteCategoryFilter: {},
  CartLineItemMessage: {},
  Status: {},
}
const themeOptions: Pick<ThemeOptions, any> = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiCard: {
      elevation: 0,
    },
    MuiAppBar: {
      elevation: 0,
    },
    MuiLink: {
      underline: "always",
      color: "textPrimary",
    },
  },
  palette: {
    primary: {
      main: colors.black[400],
      dark: colors.black[500],
    },
    secondary: {
      light: colors.grey[400],
      main: colors.grey[500],
      dark: colors.grey[600],
    },
    grey: {
      ...colors.grey,
    },
    branding: {},
    error: {
      main: colors.system.red,
    },
    warning: {
      main: colors.system.yellow,
    },
    success: {
      main: colors.system.green,
    },
    info: {
      main: colors.system.blue,
    },
    text: {
      primary: colors.black.main,
      secondary: colors.white.main,
    },
    body: {
      bgcolor: colors.body.background,
    },
    footer: {
      primary: colors.black.main,
      secondary: colors.grey[400],
    },
    header: {
      primary: colors.white.main,
      secondary: colors.black.main,
      marquee: colors.white.main,
      megaMenu: colors.white.main,
      page: colors.white.main,
    },
    ui: {
      light: colors.ui.light,
      main: colors.ui.main,
      dark: colors.ui.dark,
    },
    status: {
      inStock: colors.status.instock,
      outOfStock: colors.system.red,
    },
    product: {
      tag: {
        contrastText: colors.white.main,
        light: colors.black.main,
        main: colors.black.main,
        dark: colors.black.main,
      },
    },
    backdrop: { ...colors.backdrop },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    // default
    fontFamily: [
      "neue-haas-unica",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightBold: initialThemeOptions.fontWeightBold,
    fontWeightBoldSecondary: initialThemeOptions.fontWeightBoldSecondary,
    h1: customTypographyOptions["primary-1000"],
    h2: customTypographyOptions["primary-900"],
    button: {
      fontSize: "1rem",
    },
    // custom
    ...customTypographyOptions,
  },
  spacing: (factor: number) => `${0.25 * factor}rem`,
  overrides: { ...overrides },
}

// Customized Ignite
const theme = createTheme(themeOverride(themeOptions))

export default theme
