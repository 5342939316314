import request from "./request"
import { UserResponse } from "./users"

export type CustomerInfoSubmitResponseModel = {
  message?: string
}
export type CustomerInfoSubmitRequestModel = {
  CustomerNumber: string
  NameOfCustomer: string
  phone: string
  email: string
  message: string
}

export type EditCustomerInfoResponseModel = UserResponse

export type EditCustomerInfoRequestModel = {
  OrganizationName: string
  OrganizationNumber: string
  BillingAdressLine1: string
  BillingAdressLine2: string
  BillingAdressCity: string
  BillingAdressPostalCode: string
  BillingAdressCountryName: string
  BillingAdressCountryCode: string
  PhoneNumber: string
  Email: string
  ShippingAdressLine1: string
  ShippingAdressLine2: string
  ShippingAdressCity: string
  ShippingAdressPostalCode: string
  ShippingAdressCountryName: string
  ShippingAdressCountryCode: string
  Recipients: string
  ShippingPhoneNumber: string
}

export type CustomerInfoApi = {
  customerInfoSubmit: (
    data: CustomerInfoSubmitRequestModel
  ) => Promise<CustomerInfoSubmitResponseModel>
  editCustomerInfo: (
    data: EditCustomerInfoRequestModel
  ) => Promise<EditCustomerInfoResponseModel>
}

const customerInfoApi: CustomerInfoApi = {
  customerInfoSubmit: (data: CustomerInfoSubmitRequestModel) =>
    request.post(`/customerinfo/submit/`, data),
  editCustomerInfo: (data: EditCustomerInfoRequestModel) =>
    request.post(`/membership/user/updateinfo/`, data),
}

export default customerInfoApi
