import { usePageContext } from "@ignite/react/context/pageContext"
import { useEffect } from "react"

const ApplicationInsightsTracker: React.FC = () => {
  const {
    state: { page },
  } = usePageContext()

  useEffect(() => {
    if (page && page.data && !window.INITIAL_REQUEST) {
      if (window["ENVIRONMENT"] !== "Production") {
        console.log(
          "AI - Track pageview for subsequent request after initial load.",
          page.data.url
        )
      }
      if (window.appInsights) {
        window.appInsights.trackPageView()
      }
    }
  }, [page])

  return null
}

export default ApplicationInsightsTracker
