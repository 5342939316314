import defaultApi, { IgniteApi } from "@ignite/api"
import React from "react"

export const ApiContext = React.createContext(null as any as IgniteApi)
export const ApiContextProvider = ApiContext.Provider
export const ApiContextConsumer = ApiContext.Consumer
export const useApiContext = () => React.useContext(ApiContext)

type Props = {
  api?: IgniteApi
}

const ApiContextContainer: React.FC<Props> = ({
  children,
  api = defaultApi,
}) => <ApiContextProvider value={api}>{children}</ApiContextProvider>

export default ApiContextContainer
