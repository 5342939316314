import api from "@ignite/api"
import { TokenResponse } from "@ignite/api/users"
import Cookies from "js-cookie"

import EpiConfig from "../config"

class Jwt {
  private readonly COOKIE_NAMESPACE =
    window["COOKIE_TOKEN_NAMESPACE"] !== undefined
      ? window["COOKIE_TOKEN_NAMESPACE"]
      : "star"
  private readonly TOKEN_KEY = `${this.COOKIE_NAMESPACE}_token`
  private readonly REFRESH_TOKEN_KEY = `${this.COOKIE_NAMESPACE}_refreshtoken`
  private readonly TOKEN_EXPIRES_KEY = `${this.COOKIE_NAMESPACE}_token_expires`
  private refreshTokenRequestWatchers = 0
  private refreshTokenRequestPromise: Promise<TokenResponse> | null = null
  private readonly GRACE_PERIOD = 120000 // Time in ms (2min), to create a buffer for last calls to come in.
  payload: any | undefined

  get token(): string | undefined {
    return Cookies.get(this.TOKEN_KEY)
  }

  set token(value: string | undefined) {
    if (value) {
      this.payload = this.parseJwt(value)
      Cookies.set(this.TOKEN_KEY, value)
    }
  }

  get tokenExpires(): number {
    const expires = Cookies.get(this.TOKEN_EXPIRES_KEY)
    return expires ? Number(expires) : 0
  }

  set tokenExpires(value: number) {
    if (value) {
      Cookies.set(
        this.TOKEN_EXPIRES_KEY,
        (Date.now() + value * 1000 - this.GRACE_PERIOD).toString()
      )
    }
  }

  get refreshToken(): string | undefined {
    return Cookies.get(this.REFRESH_TOKEN_KEY)
  }

  set refreshToken(value: string | undefined) {
    if (value) {
      Cookies.set(this.REFRESH_TOKEN_KEY, value)
    }
  }

  // private ensurePayload() {
  //   if (!this.payload) {
  //     this.payload = this.parseJwt(this.token)
  //   }
  // }

  updateToken = async (): Promise<TokenResponse> => {
    if (!this.refreshToken) {
      return Promise.reject()
    }
    if (!this.refreshTokenRequestPromise) {
      if (EpiConfig.apiUseEpiConnectAuth) {
        this.refreshTokenRequestPromise = api.connect.refreshToken(
          this.refreshToken
        )
      } else {
        this.refreshTokenRequestPromise = api.users.refreshToken(
          this.refreshToken
        )
      }
    }

    this.watchRefreshToken()
    try {
      return await this.refreshTokenRequestPromise!
    } finally {
      this.unwatchRefreshToken()
    }
  }

  private watchRefreshToken = () => {
    this.refreshTokenRequestWatchers++
  }

  private unwatchRefreshToken = () => {
    this.refreshTokenRequestWatchers > 0 && this.refreshTokenRequestWatchers--
    if (this.refreshTokenRequestWatchers === 0) {
      this.refreshTokenRequestPromise = null
    }
  }

  parseJwt(token?: string): any {
    if (!token) {
      return {}
    }
    try {
      return JSON.parse(atob(token.split(".")[1]))
    } catch (e) {
      console.warn(e)
      return {}
    }
  }

  clear() {
    Cookies.remove(this.TOKEN_KEY)
    Cookies.remove(this.TOKEN_EXPIRES_KEY)
    Cookies.remove(this.REFRESH_TOKEN_KEY)
    this.payload = undefined
  }
}

export default new Jwt()
