export const colors = {
  body: {
    background: "#f5f5f5",
  },
  backdrop: {
    light: "rgba(0, 0, 0, 0.20)",
    main: "rgba(0, 0, 0, 0.25)",
    dark: "rgba(0, 0, 0, 0.5)",
    contrastText: "#FFFFFF",
  },
  white: {
    main: "#FFFFFF",
  },
  black: {
    main: "#000000",
    400: "#282828",
    500: "#111111",
  },
  grey: {
    400: "#F5F5F5",
    500: "#EEEEEE",
    650: "#dcdcde",
    600: "#C4C4C4",
    700: "#696961",
  },
  ui: {
    light: "#F6F6F5",
    main: "#DEDEDC",
    dark: "#C6C6C2",
  },
  status: {
    instock: "#24BA74",
  },
  system: {
    red: "#BB1100",
    yellow: "#FFB62D",
    green: "#88BB00",
    blue: "#4A90E2",
  },
}

/**
 * Ignite Theme - Colors
 */
export default colors
