import request from "./request"

// eslint-disable-next-line @typescript-eslint/ban-types
export type MarketsResponse = {
  markets: Market[]
  languages: MarketOption[]
  countries: MarketOption[]
  currencies: MarketOption[]
}

export type Market = {
  marketId: string
  displayName: string
  isSelected: boolean
}

export type MarketOption = {
  code: string
  displayName: string
  isSelected: boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type MarketsApi = {
  getMarkets: () => Promise<MarketsResponse>
  setMarket: (
    marketId?: string,
    language?: string,
    currency?: string,
    country?: string
  ) => Promise<MarketsResponse>
}

const marketsApi: MarketsApi = {
  getMarkets: () => request.get<MarketsResponse>(`/markets`),
  setMarket: (
    marketId?: string,
    language?: string,
    currency?: string,
    country?: string
  ) =>
    request.post<MarketsResponse>(`/markets`, {
      marketId,
      language,
      currency,
      country,
    }),
}

export default marketsApi
