import { CartItem, CartResponse } from "@ignite/api/cart"
import { Address, CheckoutResponseModel } from "@ignite/api/checkout"
import { ContentResponse } from "@ignite/api/contents"
import { useMarketContext } from "@ignite/react/context/marketContext"
import { usePageContext } from "@ignite/react/context/pageContext"
import useSystemLinks from "@ignite/utils/systemLinksUtil"
import { useEffect } from "react"

// Taken from https://youmightnotneed.com/lodash#union
const union = (arr: string[], ...args: string[]) => [
  ...new Set(arr.concat(...args)),
]

const Klaviyo: React.FC = () => {
  const {
    state: { page },
  } = usePageContext()

  const {
    state: { marketOptions },
  } = useMarketContext()

  const links = useSystemLinks()

  const klaviyo = window["klaviyo"]

  useEffect(() => {
    if (marketOptions.data && !window.BOT_DETECTED && klaviyo) {
      klaviyo.identify({
        Market: marketOptions.data?.markets.find((m) => m.isSelected)?.marketId,
        Language: window.LANGUAGE_CODE,
      })
    }
  }, [marketOptions.data, klaviyo])

  useEffect(() => {
    if (
      page &&
      page.data &&
      page.data.type === "IgniteCategory" &&
      !window.BOT_DETECTED &&
      klaviyo
    ) {
      klaviyo.push([
        "track",
        "Viewed Category",
        {
          CategoryName: page.data.properties.displayName,
          CategoryID: page.data.properties.categoryCode,
          URL: page.data.url,
        },
      ])
    }
  }, [page, klaviyo])

  useEffect(() => {
    if (window.BOT_DETECTED) return

    const eventListener = (e: Event) => {
      if (!klaviyo) {
        return
      }

      const customEvent = e as CustomEvent
      const emittedEvent = customEvent.detail

      const payload = getPayloadByEvent(emittedEvent, page && page.data, links)

      // Identify the visitor when he has filled out the first part of the checkout...
      if (
        emittedEvent.eventType === "checkout" &&
        emittedEvent.data.step === 2 &&
        payload
      ) {
        const address = emittedEvent.data.address as Address

        klaviyo
          .identify({
            email: address.email,
            first_name: address.firstName,
            last_name: address.lastName,
            Language: window.LANGUAGE_CODE,
          })
          .then(() => klaviyo.push(["track", payload.eventName, payload.data]))
      } else {
        if (payload) {
          klaviyo.push(["track", payload.eventName, payload.data])
        }
      }
    }

    window.addEventListener("igniteEvent", eventListener)

    return () => {
      window.removeEventListener("igniteEvent", eventListener)
    }
  }, [page, klaviyo, links])

  return null
}

const getPayloadByEvent: any = (
  emittedEvent: any,
  page: ContentResponse,
  links: any
) => {
  if (!emittedEvent?.data || !emittedEvent?.eventType) {
    return null
  }

  const addedItem = emittedEvent.data.lineItem as CartItem
  const cart = emittedEvent.data.cart as CartResponse
  const checkout = emittedEvent.data.checkout as CheckoutResponseModel

  switch (emittedEvent.eventType) {
    case "searched": {
      return {
        eventName: "Searched Site",
        data: {
          SearchTerm: emittedEvent.data.query,
          ReturnedResults: emittedEvent.data.hitCount,
        },
      }
    }

    case "productPageView":
      return {
        eventName: "Viewed Product",
        data: {
          ProductName: emittedEvent.data.displayName,
          ProductId: emittedEvent.data.variation.properties.code,
          SKU: emittedEvent.data.variation.properties.code,
          Categories:
            emittedEvent.data.categoryPath &&
            emittedEvent.data.categoryPath.split("/"),
          ImageUrl: emittedEvent.data.variation.properties.media[0]?.url,
          URL: page?.url,
          Brand: emittedEvent.data.brand,
          Price: emittedEvent.data.variation.properties.calculatedPriceExclTax,
        },
      }

    case "productBundlePageView":
      return {
        eventName: "Viewed Product",
        data: {
          ProductName: emittedEvent.data.displayName,
          ProductId: emittedEvent.data.vcode,
          SKU: emittedEvent.data.code,
          Categories:
            emittedEvent.data.categoryPath &&
            emittedEvent.data.categoryPath.split("/"),
          ImageUrl: emittedEvent.data.media[0]?.url,
          URL: page?.url,
          Brand: emittedEvent.data.brand,
          Price: emittedEvent.data.displayPriceExclTax,
        },
      }

    case "addToCart":
      return {
        eventName: "Added to Cart",
        data: {
          $value: cart.total,
          AddedItemProductName: addedItem.displayName,
          AddedItemProductID: addedItem.code,
          AddedItemSKU: addedItem.code,
          AddedItemCategories: addedItem.properties.category?.split("/"),
          AddedItemImageURL: addedItem.properties.productImageUrl,
          AddedItemURL: `https://${window.location.hostname}${addedItem.properties.productUrl}`,
          AddedItemPrice: addedItem.price,
          AddedItemQuantity: emittedEvent.data.quantity,
          ItemNames: union(cart.items.map((lineItem) => lineItem.displayName)),
          CheckoutURL: `https://${window.location.hostname}${links["checkout"]}`,
          Items: getLineItems(cart),
        },
      }

    case "checkout":
      return (
        emittedEvent.data.step === 2 && {
          eventName: "Started Checkout",
          data: {
            $event_id: `${checkout.cart["orderGroupId"]}_${Date.now()}`,
            $value: checkout.cart.total,
            ItemNames: union(
              checkout.cart.items.map((lineItem) => lineItem.displayName)
            ),
            CheckoutURL: window.location.href,
            Categories: union(
              [].concat(
                ...checkout.cart.items.map((lineItem) =>
                  lineItem.properties.category?.split("/")
                )
              )
            ),
            Items: getLineItems(checkout.cart),
          },
        }
      )

    default:
      return null
  }
}

const getLineItems = (cart: CartResponse) => {
  return cart.items.map((lineItem) => {
    return {
      ProductID: lineItem.code,
      SKU: lineItem.code,
      ProductName: lineItem.displayName,
      Quantity: lineItem.quantity,
      ItemPrice: lineItem.price,
      RowTotal: lineItem.totalPrice,
      ProductURL: `https://${window.location.hostname}${lineItem.properties.productUrl}`,
      ImageURL: lineItem.properties.productImageUrl,
      ProductCategories: lineItem.properties.category?.split("/"),
    }
  })
}

export default Klaviyo
