import { authContextCreator } from "@ignite/react/context/authContext"
import { cartContextCreator } from "@ignite/react/context/cartContext"
import { checkoutContextCreator } from "@ignite/react/context/checkoutContext"
import { menuContextCreator } from "@ignite/react/context/menuContext"
import { pageContextCreator } from "@ignite/react/context/pageContext"
import { searchContextCreator } from "@ignite/react/context/searchContext"
import { siteSettingsContextCreator } from "@ignite/react/context/siteSettingsContext"
import React from "react"

import GlobalStateProvider from "../context/GlobalStateProvider"
import { marketContextCreator } from "../context/marketContext"
import { userContextCreator } from "../context/userContext"

const GlobalStateContainer: React.FC = ({ children }) => (
  <GlobalStateProvider
    stateContextCreators={{
      userContextCreator,
      pageContextCreator,
      menuContextCreator,
      siteSettingsContextCreator,
      searchContextCreator,
      cartContextCreator,
      marketContextCreator,
      checkoutContextCreator,
      authContextCreator,
    }}
  >
    {children}
  </GlobalStateProvider>
)

export default GlobalStateContainer
