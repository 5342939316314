import "i18n"

import { IgniteApi } from "@ignite/api"
import { isSearchBot } from "@ignite/utils/device"
import CssBaseline from "@material-ui/core/CssBaseline"
import {
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles"
import { SheetsRegistry } from "jss"
import React, { Suspense, useMemo } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { Route, Routes } from "react-router-dom"

import ApplicationInsightsTracker from "../components/ApplicationInsightsTracker"
import DataLayerPublisher from "../components/DataLayerPublisher"
import GoogleAnalyticsTracker from "../components/GoogleAnalyticsTracker"
import Prerender from "../components/Prerender"
import CustomRouter from "../components/Route/CustomRouter"
import Spinner from "../components/Spinner"
import ApiContextContainer from "../context/ApiContextContainer"
import theme from "../theme"
import GlobalErrorBoundary from "./GlobalErrorBoundary"
import GlobalStateContainer from "./GlobalStateContainer"
import InitialLoader from "./InitialLoader"

export type AppProps = {
  /**
   * Component to show image when error occurs inside react
   */
  errorImage?: React.ReactElement
  /**
   * Children passed to Helmet
   */
  headChildren?: React.ReactNode
  /**
   * Override the default API
   */
  api?: IgniteApi
  /**
   * Array of css urls which are injected in head
   */
  styleSheets?: string[]
}

const GlobalStyles: React.FC<Pick<AppProps, "styleSheets">> = ({
  children,
  styleSheets = [],
}) => {
  useStyles()

  return (
    <Helmet>
      {children}
      {styleSheets.map((url, i) => (
        <link key={i} rel="stylesheet" href={url} />
      ))}
    </Helmet>
  )
}

const App: React.FC<AppProps> = ({
  errorImage,
  api,
  headChildren,
  children,
  styleSheets = ["https://use.typekit.net/vup5jgz.css"],
}) => {
  const { sheetsRegistry, sheetsManager } = useMemo(
    () => ({
      sheetsManager: new Map(),
      sheetsRegistry: (isSearchBot() && new SheetsRegistry()) || undefined,
    }),
    []
  )

  return (
    <StylesProvider
      sheetsRegistry={sheetsRegistry}
      sheetsManager={sheetsManager}
    >
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <GlobalStyles styleSheets={styleSheets}>{headChildren}</GlobalStyles>
          <GlobalErrorBoundary errorImage={errorImage}>
            <CssBaseline />
            <Suspense fallback={<Spinner />}>
              <ApiContextContainer api={api}>
                <GlobalStateContainer>
                  <CustomRouter>
                    <Prerender sheetsRegistry={sheetsRegistry} />
                    <DataLayerPublisher />
                    <GoogleAnalyticsTracker />
                    <ApplicationInsightsTracker />
                    <InitialLoader />
                    <Routes>
                      <Route path="*" element={children} />
                    </Routes>
                  </CustomRouter>
                </GlobalStateContainer>
              </ApiContextContainer>
            </Suspense>
          </GlobalErrorBoundary>
        </HelmetProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}

const useStyles = makeStyles(({ overrides = {}, typography }) => ({
  "@global": {
    a: {
      color: "inherit",
      textDecoration: "none",
      cursor: "pointer",
    },
    html: {
      overflowY: "scroll",
      overscrollBehavior: "none",
      ...overrides.App?.html,
    },
    body: {
      fontSize: typography["primary-300"].fontSize,
      backgroundColor: "transparent",
    },
  },
}))

export default App
