import request from "./request"

export type ShippingOptionsResponse = {
  id: string
  deliveryCheckoutId: string
  currency: string
  language: string
  options?: ShippingOption[]
}

export type ShippingOption = {
  id: string
  name: string
  logo: string
  description: string
  carrier: string
  priceValue: number
  priceDescription: string
  serviceId: string
  subOptions: any[] // not sure yet
  agents: any[] // not sure yet
  fields: any[] // not sure yet
  addons: any[] // not sure yet
  noDefaultAgent: boolean
}

export type UnifaunApi = {
  getShippingOptions: () => Promise<ShippingOptionsResponse>
}

const unifaunApi: UnifaunApi = {
  getShippingOptions: () =>
    request.get<ShippingOptionsResponse>(`/unifaun/shippingmethods`),
}

export default unifaunApi
