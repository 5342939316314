import { MenuResponse } from "@ignite/api/menus"

import { asyncState, createStateContext } from "."

const [useMenuContext, menuContextCreator] = createStateContext(
  {
    open: false,
    menus: asyncState<MenuResponse[]>(),
  },
  {
    toggle: ({ open, ...state }) => ({ ...state, open: !open }),
  }
)

export { useMenuContext, menuContextCreator }
