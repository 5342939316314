/* eslint-disable @typescript-eslint/no-unused-vars, no-redeclare  */
import React, { useMemo } from "react"

export type StateContext<S = any, A = any> = {
  state: S
  actions: A
  setState: React.Dispatch<React.SetStateAction<S>>
  useAsyncAction: S extends PrimitiveState | Array<infer P>
    ? () => Promise<any>
    : AsyncAction<S>
}

type AsyncPromiseType<K extends keyof S, S> = S[K] extends AsyncState<infer P>
  ? (...args: any) => Promise<P>
  : (...args: any) => Promise<S[K]>

export type AsyncAction<S> = <
  K extends keyof S,
  P extends AsyncPromiseType<K, S>
>(
  key: K,
  promiseFunction: P,
  options?: { throwError?: boolean; deps?: any[] }
) => P

export type AsyncState<T> = {
  loading: boolean
  error?: Record<string, unknown> | string
  data: T
}

export function asyncState<T>(): AsyncState<T | null>
export function asyncState<T>(data: T): AsyncState<T>
export function asyncState<T>(data?: T): AsyncState<T> | AsyncState<T | null> {
  return {
    data: data || null,
    loading: false,
  }
}

export type StateContextCreator<S = any, A = any> = {
  state: S
  reducers: ReducerFunctions<S>
  context: React.Context<StateContext<S, A>>
}

export type ReducerFunctions<S> = Record<string, (state: S, payload: any) => S>

export type EmptyReducerFunction<S> = () => S

export type StateReducerFunction<S> = (state: S) => S

type PrimitiveState = string | symbol | number | boolean

type ExtractPayload<S, T> = T extends (state: S, payload: infer P) => S
  ? P
  : never

type ContextActions<S, R> = {
  [T in keyof R]: ExtractPayload<S, R[T]> extends undefined | null
    ? () => void
    : R[T] extends StateReducerFunction<S>
    ? () => void
    : R[T] extends EmptyReducerFunction<S>
    ? () => void
    : (payload: ExtractPayload<S, R[T]>) => void
} & {
  reset: S extends PrimitiveState | Array<infer P>
    ? () => void
    : (key?: keyof S) => void
  set: S extends PrimitiveState | Array<infer P>
    ? (state: S) => void
    : <K extends keyof S, T extends S[K]>(key: K, value: T) => void
}

export function createStateContext<S, R>(
  initialState: S,
  reducers: R & ReducerFunctions<S>
): [
  () => StateContext<S, ContextActions<S, R>>,
  StateContextCreator<S, ContextActions<S, R>>
]
export function createStateContext<S>(
  initialState: S,
  ...reducerArray: any[]
): [
  () => StateContext<S, ContextActions<S, any>>,
  StateContextCreator<S, any>
] {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const context = React.createContext<StateContext>({
    state: initialState,
    actions: null as any,
    setState: null as any,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useAsyncAction: () => useMemo(() => () => {}, []) as any,
  })

  const reducers = reducerArray.reduce<ReducerFunctions<any>>((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      acc[key] = curr[key]
    })
    return acc
  }, {})

  return [
    () => {
      const contextValue = React.useContext(context)
      if (!contextValue || !contextValue.setState) {
        console.error(
          "Global context value not found, did you forget provide the context creator to your GlobalStateProvider component?\nCheck stacktrace of this error message:"
        )
      }
      return contextValue
    },
    {
      state: initialState,
      context,
      reducers,
    },
  ]
}
