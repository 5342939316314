import emitEvent from "@ignite/utils/eventEmitter"
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button"
import clsx from "clsx"
import React from "react"

export type ButtonProps = MuiButtonProps & {
  event?: string
}

export const Button: React.FC<ButtonProps> = ({
  variant = "contained",
  color = "primary",
  event,
  children,
  onClick,
  className,
  ...restProps
}) => {
  const handleOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (onClick) {
      onClick(e)
    }
    if (event) {
      emitEvent(event)
    }
  }
  // const classes = useStyles()
  return (
    <MuiButton
      onClick={((event || onClick) && handleOnClick) || undefined}
      color={color}
      variant={variant}
      className={clsx(className)}
      {...restProps}
    >
      {children}
    </MuiButton>
  )
}

export default Button
