import request from "./request"

export type SubmitClaimRequestModel = {
  CustomerNumber: number
  NameOfCustomer: string
  PhoneNumber: number
  Email: string
  OrderNumber: number
  InvoiceNumber: number
  Marking: string
  ClaimArticles: {
    ArticleNumber: number
    NumberOfArticles: number
    CauseOfClaim: string
    Description: string
  }[]
  Image: string[]
  ContactPerson: string
}

export type ClaimsApi = {
  submitClaim: (data: FormData) => Promise<void>
}

const claimsApi: ClaimsApi = {
  submitClaim: (formData) =>
    request.post(`/claim/submit`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
}

export default claimsApi
