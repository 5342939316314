import { CartItem, CartName, CartResponse } from "@ignite/api/cart"

import { asyncState, createStateContext } from "."

export type SnackbarMessage = {
  item?: CartItem
  name: CartName
  headerTitle?: string
  bodyComponent?: React.FC<any>
  cta?: () => void
  buttonText?: string
}

export const defaultState = {
  open: {} as Record<CartName, boolean>,
  itemCount: {} as Record<CartName, number>,
  carts: asyncState<CartResponse[]>(),
  snackbarMessage: null as SnackbarMessage | null,
}

const [useCartContext, cartContextCreator] = createStateContext(defaultState, {
  toggle: ({ open, ...state }, name: CartName) => ({
    ...state,
    open: {
      ...open,
      [name]: open[name] ? !open[name] : true,
    },
  }),

  setSnackbar: (state, snackbarMessage: SnackbarMessage) => ({
    ...state,
    snackbarMessage,
  }),
})

export { useCartContext, cartContextCreator }
