import request from "./request"

// eslint-disable-next-line @typescript-eslint/ban-types
/** For further docs please check https://docs.adyen.com/checkout/drop-in-web */
export type AdyenApi = {
  /** Pass to paymentMethodsResponse and configure Adyen*/
  getPaymentMethods: () => Promise<any[]>

  /** state.data.paymentMethod from onSubmit event */
  createPayment: (data: any) => Promise<any>

  /** state.data from onAdditionalDetails event */
  additionalData: (data: any) => Promise<any>
}

const adyenApi: AdyenApi = {
  getPaymentMethods: () => request.get<any>(`/adyen/paymentmethods`),
  createPayment: (data) => request.post<any>(`/adyen/payments/create`, data),
  additionalData: (data) =>
    request.post<any>(`/adyen/payments/additionaldata`, data),
}

export default adyenApi
