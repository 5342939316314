import React, { useEffect, useState } from "react"

import Content from "../components/Content"
import componentLoader from "../components/Content/componentLoader"

const loadComponent = (type: string) => componentLoader("shell", type)

const DefaultApp: React.FC = () => {
  const [[Header, Footer], setHeaderFooter] = useState<
    [React.ComponentType | null, React.ComponentType | null]
  >([null, null])

  useEffect(() => {
    ;(async () => {
      const [headerComponent, footerComponent] = await Promise.all(
        ["Header", "Footer"].map(loadComponent)
      )
      setHeaderFooter([headerComponent, footerComponent])
    })()
  }, [])

  return (
    <>
      {Header && <Header />}
      <Content />
      {Footer && <Footer />}
    </>
  )
}

export default DefaultApp
