export const colors = {
  text: {
    secondary: "#FFFFFF",
  },
  white: {
    main: "#FFFFFF",
  },
  black: {
    main: "#000000",
  },
  grey: {
    light: "#F6F6F5",
    400: "#C4C4C4",
    500: "#DEDEDC",
    600: "#C6C6C2",
    700: "#696961",
  },
  red: {
    main: "#BB1100",
    light: "#E36367",
  },
  branding: {
    black: { 400: "#8E8E85", 450: "#696961", 500: "#121210", 600: "#111111" },
    green: {
      300: "#EBF3F0",
      main: "#006441",
      500: "#006441",
      dark: "#003C27",
      550: "#005C3C",
    },
    purple: {
      light: "#F2EAF4",
      200: "#FAF7FB",
      300: "#F2EAF4",
      350: "#782B9026",
      500: "#782D91",
      main: "#510b61",
      dark: "#481B57",
    },
    brown: {
      300: "#F5F0EA",
      main: "#90632C",
      dark: "#563B1A",
    },
    yellow: {
      main: "#ffd30f",
      dark: "#eec200",
    },
  },
}

/**
 * Nelson Theme - Colors
 */
export default colors
