import { Location } from "history"
export const QUERY_DELIMITER = "?"

export const extractPath = (location: Location) =>
  location.pathname.split(QUERY_DELIMITER)[0]

export const extractQuery = (location: Location) =>
  location.pathname.split(QUERY_DELIMITER)[1]

export const isLocalHost = () =>
  window.location.hostname === "localhost" ||
  window.location.host === "127.0.0.1"
