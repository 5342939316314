import { typography } from "@ignite/react/theme/typography"
import { Breakpoints } from "@material-ui/core/styles/createBreakpoints"
import { TypographyOptions } from "@material-ui/core/styles/createTypography"

/**
 * Nelson Theme - Typography
 * @param breakpoints
 */
export const createTypography = (
  breakpoints: Breakpoints
): TypographyOptions => ({
  ...typography({ breakpoints }),
})
