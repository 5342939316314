import { ThemeOptions } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
// eslint-disable-next-line no-restricted-imports
import { TypographyOptions } from "@material-ui/core/styles/createTypography"

import colors from "./colors"
import { createTypography } from "./typography"

const breakpoints = createBreakpoints({})
const customTypographyOptions = createTypography(breakpoints)

const themeOverride = (options: ThemeOptions) => {
  const spacing = options.spacing as (value?: number) => number | string
  if (options.typography) {
    options.typography = {
      // default
      fontFamily: ["azo-sans-web", "sans-serif"].join(","),
      fontWeightBold: 700,
      fontFamilySecondary: ["Source Serif Pro", "serif"].join(","),
      fontWeightBoldSecondary: 700,
      h1: customTypographyOptions["primary-800"],
      h2: { ...customTypographyOptions["primary-600"], fontWeight: 700 },
      h3: customTypographyOptions["primary-450"],
      h4: customTypographyOptions["primary-400"],
      // custom
      ...customTypographyOptions,
    } as TypographyOptions
  }
  if (options.palette) {
    options.palette.primary = {
      light: colors.branding.purple.light,
      main: colors.branding.purple.main,
      dark: colors.branding.purple.dark,
    }
    options.palette.secondary = {
      light: colors.branding.green.main,
      main: colors.branding.green.main,
      dark: colors.branding.green.dark,
    }

    if (options.palette.grey) {
      options.palette.grey[700] = colors.grey[700]
      options.palette.grey[100] = colors.grey.light
    }

    if (options.palette.branding) {
      options.palette.branding = {
        ...colors.branding,
      }
    }

    options.palette.header = {
      ...options.palette.header,
      primary: colors.branding.green.main,
      secondary: colors.branding.green[550],
      marquee: colors.branding.green[300],
      page: colors.white.main,
    }
    options.palette.footer = {
      primary: colors.branding.green.main,
      secondary: colors.white.main,
    }
    options.palette.product.tag = {
      contrastText: colors.white.main,
      light: colors.branding.green.main,
      main: colors.branding.green.main,
      dark: colors.branding.green.dark,
    }
    if (options.palette.text) {
      options.palette.text.secondary = colors.text.secondary
    }
  }

  if (options.overrides) {
    if (options.overrides.App) {
      options.overrides.App = {
        html: {
          backgroundColor: options.palette?.body.bgcolor,
          "@global": {
            ".nelson-cta-link": {
              fontWeight: 500,
              color: `${colors.branding.purple.main} !important`,
              textDecoration: "none !important",
              textUnderlinePosition: "initial !important",
              "&:active": {
                color: colors.branding.purple.main,
              },
              "&:after": {
                content: "''",
                position: "relative",
                display: "inline-block",
                width: "1em",
                height: "1em",
                top: "2px",
                right: "-0.5em",
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='${encodeURIComponent(
                  colors.branding.purple.main
                )}' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke-width='2' d='M5 12h14m-7-7l7 7-7 7' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E")`,
              },
            },
          },
        },
      }
    }
    if (options.overrides.MuiButton) {
      options.overrides.MuiButton.root = {
        ...options.overrides.MuiButton.root,
        [breakpoints.down("sm")]: {
          lineHeight: 1,
        },
      }
      options.overrides.MuiButton.containedPrimary = {
        backgroundColor: colors.branding.yellow.main,
        color: colors.black.main,
        borderRadius: 48,
        fontWeight: options.typography?.fontWeightBold,
        "&:hover": {
          backgroundColor: `${colors.branding.yellow.dark} !important`,
        },
      }
      options.overrides.MuiButton.containedSecondary = {
        backgroundColor: colors.white.main,
        color: colors.branding.purple.main,
        borderRadius: 48,
        fontWeight: options.typography?.fontWeightBold,
        "&:hover": {
          backgroundColor: `${colors.grey.light} !important`,
        },
      }
      options.overrides.MuiButton.outlinedPrimary = {
        backgroundColor: colors.white.main,
        color: colors.branding.purple.main,
        border: `1px solid ${colors.branding.purple.main}`,
        borderRadius: 48,
        fontWeight: options.typography?.fontWeightBold,
        "&:hover": {
          backgroundColor: `${colors.grey.light} !important`,
        },
      }

      options.overrides.Dialog = {
        ...options.overrides.Dialog,
        paper: {
          maxWidth: 627,
          borderRadius: 12,
          padding: spacing(4),
          margin: spacing(4),

          [breakpoints.up("md")]: {
            width: 627,
            padding: spacing(12),
          },
        },
      }

      options.overrides.TopSectionButton = {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.08)",
          borderColor: "rgba(255, 255, 255, 0.4)",
          color: "#fff",
          fontSize: 12,
          padding: `${spacing(0.5)} ${spacing(6)}`,

          "&:hover": {
            backgroundColor: "transparent !important",
            borderColor: "rgba(255, 255, 255, 0.4) !important",
          },
        },
        icon: {
          marginRight: spacing(2),
        },
      }

      options.overrides.MuiButton.textPrimary = {
        color: colors.branding.purple.main,
        fontWeight: options.typography?.fontWeightBold,
        "&:hover": {
          color: colors.branding.purple.main,
          backgroundColor: "transparent",
        },
        "&$disabled": {
          color: colors.branding.purple.main,
          opacity: 0.4,
          backgroundColor: "transparent",
        },
      }
    }
    if (options.overrides.MuiInput && options.palette?.primary) {
      options.overrides.MuiInput = {
        ...options.overrides.MuiInput,
        input: {
          ...options.overrides.MuiInput.input,
          borderRadius: 4,
        },
        underline: {
          ...options.overrides.MuiInput.underline,
          "&:after": {
            borderBottomColor: options.palette.primary["main"],
          },
          "&$focused:not($disabled):after": {
            borderBottomColor: options.palette.primary["main"],
          },
        },
      }
    }
    if (options.overrides.MuiSelect) {
      options.overrides.MuiSelect = {
        select: {
          borderRadius: 4,
          "&:focus": {
            borderRadius: 4,
          },
        },
      }
    }
    if (options.overrides.MuiBadge) {
      options.overrides.MuiBadge = {
        colorPrimary: {
          color: colors.black.main,
          backgroundColor: colors.branding.yellow.main,
          fontWeight: 700,
        },
      }
    }
    if (options.overrides.MuiChip) {
      options.overrides.MuiChip = {
        colorSecondary: {
          color: colors.branding.purple.main,
          backgroundColor: colors.branding.purple.light,
        },
        sizeSmall: {
          fontSize: 12,
          paddingTop: 2,
        },
      }
    }
    if (options.overrides.ProductSection) {
      options.overrides.ProductSection = {
        root: {
          backgroundColor: colors.white.main,
        },
      }
    }
    if (options.overrides.ProductCardTag) {
      options.overrides.ProductCardTag = {
        customTag: {
          borderRadius: "2px 2px 0px 0px",
          padding: `${spacing(1)} ${spacing(5)} 0`,
          backgroundColor: colors.branding.green.main,
          "&:after": {
            borderRadius: "2px 2px 0px 0px",
            position: "absolute",
            display: "block",
            height: "10px",
            width: "100%",
            content: "''",
            bottom: "-10px",
            left: 0,
            zIndex: 1,
            transform: "scaleY(-1)",
            clipPath: "polygon(0 65%, 50% 0, 100% 55%, 100% 100%, 0 100%)",
            backgroundColor: colors.branding.green.main,
          },
        },
        text: {
          marginTop: "2px",
          fontSize: "13px",
        },
        offer: {
          backgroundColor: colors.branding.purple.main,
          "&:after": {
            backgroundColor: colors.branding.purple.main,
          },
        },
        new: {
          backgroundColor: colors.branding.green.main,
          "&:after": {
            backgroundColor: colors.branding.green.main,
          },
        },
      }
    }

    if (options.overrides.ProductPage) {
      options.overrides.ProductPage = {
        primaryDisplayNameText: {
          fontWeight: 400,
          fontSize: 44,
          lineHeight: "48px",
        },
        secondaryDisplayNameText: {
          color: colors.branding.black["500"],
        },
        articleNo: {
          fontFamily: options.typography?.fontFamily,
          color: colors.branding.black["400"],
          fontSize: 14,
          lineHeight: "24px",
        },
      }
    }

    if (options.overrides.Price) {
      options.overrides.Price = {
        sales: {
          color: colors.red.light,
        },
      }
    }

    if (options.overrides.PageHeader) {
      options.overrides.PageHeader = {
        root: {
          backgroundColor: colors.white.main,
        },
        contentWrapper: {
          position: "relative",
          padding: `${spacing(38)} ${spacing(18)}`,
          backgroundColor: colors.white.main,
          width: "50%",
          [breakpoints.between(960, "md")]: {
            width: "70%",
            padding: `${spacing(38)} ${spacing(16)}`,
          },
        },
        title: {
          "&:before": {
            content: "''",
            width: "110px",
            height: "110px",
            backgroundSize: "110px 110px",
            display: "inline-block",
            position: "absolute",
            transform: "translate(-73px, -76px)",
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='125' height='121' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.313 79.628l.63-.13c7.352-2.513 14.9-4.081 22.027-2.937 9.526 1.63 15.84 3.598 21.326 12.63 4.53 7.589 7.025 21.178 4.823 21.635-2.201.458-2.332-.171-3.3-3.251-1.164-4.023-4.66-8.218-7.765-10.526-5.134-4.183-11.46-7.79-18.143-8.37-.629.131-1.952.078-1.822.707.196.944 2.66 1.744 3.733 2.177 7.206 3.096 14.423 7.83 19.071 14.411 0 0 .065.314.38.249 1.021 1.756 2.042 3.513 2.434 5.4.13.629.196.943-.433 1.074-7.115.494-14.545 1.054-21.12-2.173-7.651-3.66-11.237-11.445-15.57-18.09-1.402-2.006-2.738-3.697-4.073-5.388-1.336-1.692-1.27-1.377-2.606-3.068-1.715-1.94-2.357-3.447.409-4.35zM101.936 2.332l-.943.196C90.536 2.732 79.961 3.945 71.18 9.05c-11.482 6.323-18.875 11.796-21.857 25.867-2.496 11.674 1.11 30.61 3.941 30.022 3.145-.654 2.634-1.532 2.728-5.817-.167-5.542 2.3-12.617 5.225-17.49 4.669-7.531 11.224-15.455 19.324-19.107.63-.13 2.765-.902 2.961.041.262 1.258-2.871 3.55-3.684 4.375-7.63 7.49-14.868 16.869-17.625 27.284.065.315-.25.38-.184.694-.8 2.463-.907 5.11-.633 8.006.13.63.261 1.258.89 1.127 9.305-2.589 19.238-5.31 26.305-12.355 7.814-8.185 8.92-20.226 11.678-30.642.67-3.092 1.72-5.935 2.769-8.777 1.05-2.843.801-2.463 1.851-5.306.8-2.463.972-4.796-2.933-4.64z' fill='%23006441'/%3E%3C/svg%3E")`,
          },
          [breakpoints.down(1750)]: {
            "&:before": {
              width: "90px",
              height: "90px",
              transform: "translate(-56px, -58px)",
              backgroundSize: "90px 90px",
            },
          },
          [breakpoints.down("sm")]: {
            "&:before": {
              content: "none",
            },
          },
        },
        imageWrapper: {
          position: "relative",
          width: "50%",
          overflow: "hidden",
          "& img": {
            maxHeight: "none",
          },
          "&:before": {
            content: "''",
            top: "-5px",
            left: "-1px",
            position: "absolute",
            height: "105%",
            width: "150px",
            zIndex: 1,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "0 50%",
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48.5 562'%3E%3Cpath d='M22 0s55 78 6.5 281 0 281 0 281H0V0z' fill='%23fff'/%3E%3C/svg%3E")`,
            [breakpoints.down("sm")]: {
              backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 562 48.5'%3E%3Cpath d='M0 26.5s78-55 281-6.5 281 0 281 0v28.5H0z' fill='%23fff'/%3E%3C/svg%3E")`,
              backgroundPosition: "50% 100%",
              top: "auto",
              bottom: "-1px",
              width: "calc(100% + 2px)",
              left: "-1px",
              height: spacing(32),
            },
          },
          [breakpoints.down("sm")]: {
            minHeight: spacing(62),
            order: -1,
            width: "100%",
          },
        },
      }
    }

    if (options.overrides.ContentPageCard) {
      options.overrides.ContentPageCard = {
        content: {
          padding: `${spacing(4)}`,
        },
        link: {
          color: colors.branding.purple.main,
          "& > span": {
            verticalAlign: "middle",
          },
          "& > svg": {
            verticalAlign: "middle",
            marginLeft: spacing(2),
          },
        },
        body: {
          [breakpoints.down("sm")]: {
            ...customTypographyOptions["secondary-300"],
          },
        },
        image: {
          borderRadius: "12 0",
        },
        root: {
          borderRadius: 12,
          flex: 1,
        },
      }
    }

    if (options.overrides.HighlightCard) {
      options.overrides.HighlightCard = {
        container: {
          borderRadius: 12,
          overflow: "hidden",
        },
      }
    }

    if (options.overrides.CategoryCarouselBlock) {
      options.overrides.CategoryCarouselBlock = {
        card: {
          borderRadius: 12,
          overflow: "hidden",
        },
      }
    }

    if (options.overrides.IgniteCategoryFilter) {
      options.overrides.IgniteCategoryFilter = {
        group: {
          backgroundColor: colors.white.main,
          padding: 0,
          "& .ig-select-label": {
            transform: "translate(0, 0.75rem) scale(0.75)",
          },
        },
        drawer: { backgroundColor: colors.white.main },
        button: {
          padding: `${spacing(2)} ${spacing(6)}`,
          "&:not(button)": {
            padding: `0 ${spacing(5)}`,
          },
        },
      }
    }

    if (options.overrides.HeroBlock) {
      options.overrides.HeroBlock = {
        root: {
          height: "initial",
          minHeight: "calc(100vh - 400px)",
          overflow: "hidden",
          "&.ig-heroblock:before": {
            content: "''",
            height: "2.6vw",
            minHeight: "50px",
            width: "120%",
            bottom: -1,
            position: "absolute",
            zIndex: 2,
            filter: "brightness(200%)", // use the brightness filter to change color on background image (svg fill) e.g. 200% for white
            backgroundColor: "transparent",
            backgroundPosition: "0 0",
            backgroundSize: "cover",
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='1920' height='49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg %3E%3Cpath d='M6028.07 58.703c-796.08-83.312-1065.18 64.798-2124.75-30.01-653.5-58.473-853.4 26.883-1154.9 61.677l-495.71.805c-70.24-6.503-147.41-15.73-233.08-28.24C938-95.005 863.355 117.512 0 4.19v90.646l2252.71-3.662c214.7 19.878 364.79 14.303 495.71-.805L6328 84.55l-299.93-25.848z' fill='%23f5f5f5'/%3E%3C/g%3E%3C/svg%3E")`,
            [breakpoints.down("md")]: {
              backgroundSize: "100% 100%",
              width: "110%",
              bottom: -5,
            },
            [breakpoints.down("xs")]: {
              backgroundSize: "100% 100%",
              width: "110%",
              backgroundImage: `url("data:image/svg+xml,%3Csvg width='374' height='35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg %3E%3Cpath d='M-447.207 83c177.452 0 272.613-50.926 336.212-50.926 63.598 0 95.308-31.3 196.942-31.3 31.492.12 62.859 3.797 93.448 10.955C234.114 24.424 325 44.5 375.5 21L423 8.5S576.232 48 679.021 48h141.844v35H-447.207z' fill='%23f5f5f5'/%3E%3C/g%3E%3C/svg%3E")`,
            },
          },
        },
        contentContainer: {
          height: "initial",
          maxHeight: "initial",
          "& > h1": {
            fontWeight: 300,
            "-webkit-text-stroke": "1px #fff",
            letterSpacing: 0.2,
          },
          [breakpoints.down("sm")]: {
            paddingTop: "2.5rem",
            paddingBottom: "2.5rem",
          },
        },
      }
    }

    if (options.overrides.MediaCardBlock) {
      options.overrides.MediaCardBlock = {
        root: {
          borderRadius: 12,
          overflow: "hidden",
        },
      }
    }

    if (options.overrides.QuantityCounter) {
      options.overrides.QuantityCounter.root = {
        borderRadius: 48,
      }
    }

    if (options.overrides.MobileMenu) {
      options.overrides.MobileMenu = {
        rootTopMenu: {
          paddingTop: spacing(14),
        },
      }
    }

    options.overrides.MuiTab = {
      root: {
        padding: `${spacing(1.5)} ${spacing(4)} ${spacing(1.5)} 0 `,
        "& > span": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: 200,
          display: "block",
          [breakpoints.down("sm")]: {
            maxWidth: 140,
          },
        },
      },
      textColorInherit: {
        textTransform: "none",
        fontSize: 20,
        fontWeight: 400,
        color: colors.branding.black[600],
        minWidth: "auto",
        opacity: 1,
        "&.Mui-selected > span": {
          fontWeight: 600,
        },
      },
    }

    options.overrides.MuiTabs = {
      flexContainer: {
        borderBottom: `2px solid ${colors.grey[400]}`,
      },
    }

    if (options.overrides.MobileMenuAuthButton) {
      options.overrides.MobileMenuAuthButton = {
        root: {
          ...options.overrides.MobileMenuAuthButton.root,
          border: `1px solid ${options?.palette?.ui.main}`,
          color: colors.branding.black[500],
          fontSize: 12,
          fontWeight: options?.typography?.fontWeightLight,
        },
        icon: {
          ...options.overrides.MobileMenuAuthButton.icon,
          color: colors.branding.green.main,
        },
      }
    }

    if (options.overrides.HeaderCheckout) {
      options.overrides.HeaderCheckout = {
        root: {
          minHeight: 120,
          [breakpoints.down("sm")]: {
            minHeight: 60,
          },
        },
        logo: {
          width: 131,
          position: "absolute",
          top: 0,
        },
        link: {
          color: "#FFFFFF",
          "& > *": { verticalAlign: "middle" },
          ...options?.typography?.["primary-200"],
          fontWeight: 500,
          "&:hover": {
            color: "#FFFFFF",
            fontWeight: 500,
          },
        },
        linktext: {
          [breakpoints.down("sm")]: {
            display: "none",
          },
        },
      }
    }
    if (options.overrides.HeaderContainer) {
      options.overrides.HeaderContainer = {
        root: {
          borderBottom: "none",
          justifyContent: "center",
        },
        toolbar: {
          position: "initial",
          [breakpoints.down("sm")]: {
            position: "relative",
          },
        },
        pseudoElement: {
          minHeight: "80px",
          padding: `0 ${spacing(9)}`,
          "&:before": {
            minWidth: "131px",
            content: "''",
            marginLeft: "auto",
            flex: 1,
          },
          [breakpoints.down("sm")]: {
            minHeight: "64px",
            padding: `0 0 0 ${spacing(4)}`,
            "&:before": {
              display: "none",
            },
          },
        },
      }
    }
    if (options.overrides.Marquee) {
      options.overrides.Marquee = {
        root: {
          alignItems: "center",
          minHeight: "40px",
        },
      }
    }

    if (options.overrides.CheckoutBox) {
      options.overrides.CheckoutBox.root = {
        [breakpoints.down("sm")]: {
          paddingLeft: spacing(6),
          paddingRight: spacing(6),
        },
      }
    }

    if (options.overrides.CartLineItemMessage) {
      options.overrides.CartLineItemMessage = {
        root: {
          color: colors.red.main,
        },
      }
    }

    options.overrides.MuiRadio = {
      root: { color: options.palette?.grey?.[600] || "initial" },
    }

    options.overrides.Status = {
      root: {
        "&.inprogress": {
          color: colors.branding?.yellow?.main,
        },
      },
    }
  }

  return options
}

export default themeOverride
