import { UserResponse } from "@ignite/api/users"

import { asyncState, createStateContext } from "."

type DialogStateType = {
  open: boolean
  type: "" | "impersonation"
}

const [useUserContext, userContextCreator] = createStateContext(
  {
    user: asyncState<UserResponse>(),
    dialog: {
      open: false as boolean,
      type: "",
    } as DialogStateType,
  },
  {
    setDialog: (state, dialog: DialogStateType) => ({
      ...state,
      dialog,
    }),
  }
)

export { useUserContext, userContextCreator }
