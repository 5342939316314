import { ApiComponentType, Properties } from "."
import request from "./request"

export type MenuResponse = ApiComponentType & {
  url: string
  title: string
  children: MenuResponse[]
  properties?: Properties
}

export type MenuIdentifier = "main" | "footer" | "top"

export type MenusApi = {
  getMenuByIdentifier: (identifier: MenuIdentifier) => Promise<MenuResponse>
  getMenus: () => Promise<MenuResponse[]>
}

const menusApi: MenusApi = {
  getMenuByIdentifier: (identifier) =>
    request.get<MenuResponse>(`/menu/${identifier}`),
  getMenus: async () => {
    return window.MENU_DATA || (await request.get<MenuResponse[]>("/menus"))
  },
}

export default menusApi
