import "../i18n"
import "font/font.css"

import IgniteApp from "@ignite/react/App"
import DefaultApp from "@ignite/react/App/DefaultApp"
import Klaviyo from "components/Klaviyo"
import React from "react"

const App: React.FC = () => (
  <IgniteApp styleSheets={[]}>
    <Klaviyo />
    <DefaultApp />
  </IgniteApp>
)

export default App
