import { usePageContext } from "@ignite/react/context/pageContext"
import emitEvent from "@ignite/utils/eventEmitter"
import { useEffect } from "react"

const GoogleAnalyticsTracker: React.FC = () => {
  const {
    state: { page },
  } = usePageContext()

  useEffect(() => {
    if (page && page.data) {
      const absolute = `${window.location.protocol}//${window.location.hostname}`
      const pageUrl = page.data.url.replace(absolute, "")

      if (window.BOT_DETECTED) return

      if (window["ENVIRONMENT"] !== "Production" && !window.INITIAL_REQUEST) {
        console.log(
          "GA - Track pageview for subsequent request after initial load.",
          pageUrl
        )
      }

      if (
        window.ga &&
        typeof window.ga.getAll === "function" &&
        window.ga.getAll()[0] &&
        !window.INITIAL_REQUEST
      ) {
        const tracker = window.ga.getAll()[0].get("name")
        window.ga(`${tracker}.set`, "page", pageUrl)
        window.ga(`${tracker}.send`, "pageview")
        if (window["ENVIRONMENT"] !== "Production") {
          console.log(`Tracked to ${tracker}`)
        }
      }

      emitEvent("virtualPageView", {
        url: pageUrl,
        title: page.data.meta.title,
      })
    }
  }, [page])

  return null
}

export default GoogleAnalyticsTracker
