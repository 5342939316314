import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react"

interface State {
  expanded: boolean
}

const initialState: State = {
  expanded: false,
}

type Action = {
  type: "SET_DELIVERY_ACCORDION"
  expanded: boolean
}

export const CheckoutContext = createContext<State | any>(initialState)

CheckoutContext.displayName = "CheckoutContext"

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case "SET_DELIVERY_ACCORDION": {
      return {
        ...state,
        ...action,
      }
    }

    default:
      return state
  }
}

export const CheckoutProvider: React.FC<{ initialValue?: State }> = ({
  initialValue,
  ...props
}) => {
  const [state, dispatch] = useReducer(uiReducer, initialValue || initialState)

  const setDeliveryAccordion = useCallback(
    (expanded: boolean) =>
      dispatch({
        type: "SET_DELIVERY_ACCORDION",
        expanded,
      }),
    [dispatch]
  )

  const value = useMemo(
    () => ({
      states: {
        ...state,
      },
      actions: {
        setDeliveryAccordion,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <CheckoutContext.Provider value={value} {...props} />
}

export const useLocalCheckoutContext = () => {
  const context = useContext(CheckoutContext)
  if (context === undefined) {
    throw new Error(
      `useLocalCheckoutContext must be used within a CheckoutProvider`
    )
  }
  return context
}
