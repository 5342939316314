import { useSiteSettingsContext } from "@ignite/react/context/siteSettingsContext"
import { useMemo } from "react"

const useSystemLinks = () => {
  const {
    state: { siteSettings },
  } = useSiteSettingsContext()

  const systemLinksByName = useMemo(
    () =>
      (siteSettings &&
        siteSettings.data &&
        siteSettings.data.properties.systemLinks.reduce((acc, link) => {
          acc[link.text] = link.href
          return acc
        }, {})) ||
      {},
    [siteSettings]
  )

  return systemLinksByName
}

export default useSystemLinks
