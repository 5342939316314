const isObj = (o?: any) => o?.constructor === Object

export const objectHasProperty = (object: any, key: string) =>
  object && Object.prototype.hasOwnProperty.call(object, key)

export const nonRecursiveTreeTraversal: <T extends Record<string, unknown>>(
  data: any,
  childKeyPredicate: T,
  cb: (item: Record<keyof T, any>) => any | undefined
) => void = (data, childKeyPredicate, cb) => {
  const stack = [data]

  const checkPredicateValid = (item: any) =>
    isObj(item) &&
    !Object.keys(childKeyPredicate).some((key) => !objectHasProperty(item, key))

  while (stack.length > 0) {
    const item = stack.pop()
    if (!item) {
      continue
    }

    if (!checkPredicateValid(item)) {
      continue
    }

    const childObject = cb(item)

    if (childObject && isObj(childObject)) {
      for (const [, childValue] of Object.entries(childObject)) {
        if (Array.isArray(childValue)) {
          for (const child of childValue) {
            stack.push(child)
          }
        } else {
          stack.push(childValue)
        }
      }
    }
  }
}
