import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

import { preloadResources } from "../components/Content/componentLoader"
import { useApiContext } from "../context/ApiContextContainer"
import { useCartContext } from "../context/cartContext"
import { useMarketContext } from "../context/marketContext"
import { useMenuContext } from "../context/menuContext"
import { usePageContext } from "../context/pageContext"
import { useSiteSettingsContext } from "../context/siteSettingsContext"
import { useUserContext } from "../context/userContext"

const MAX_ITEMS = 200

const SCROLL_HISTORY_TTL_SECONDS = 1 * 60 //one hour
const SCROLL_POSITION_STORAGE_KEY = "ig-scroll-history"
const SCROLL_POSITION_INDEX_STORAGE_KEY = "ig-scroll-history-index"
const SCROLL_POSITION_DATE_STORAGE_KEY = "ig-scroll-history-date"
const NOW = new Date()

let isPopState = false

const scrollPositionsDate = new Date(
  localStorage.getItem(SCROLL_POSITION_DATE_STORAGE_KEY) || NOW.toString()
)
const scrollPositions: {
  position: number
  pathname: string
}[] =
  (NOW.getTime() - scrollPositionsDate.getTime() <
    SCROLL_HISTORY_TTL_SECONDS * 1000 &&
    JSON.parse(localStorage.getItem(SCROLL_POSITION_STORAGE_KEY) || "[]")) ||
  []

let index =
  (scrollPositions.length > 0 &&
    parseInt(localStorage.getItem(SCROLL_POSITION_INDEX_STORAGE_KEY) || "0")) ||
  0
const saveScrollPositions = () => {
  //remove first items of count
  if (scrollPositions.length > MAX_ITEMS) {
    scrollPositions.splice(0, scrollPositions.length - MAX_ITEMS)
  }
  //we can only go back this many
  index = Math.max((MAX_ITEMS - 1) * -1, index)
  localStorage.setItem(
    SCROLL_POSITION_STORAGE_KEY,
    JSON.stringify(scrollPositions)
  )
  localStorage.setItem(SCROLL_POSITION_INDEX_STORAGE_KEY, index.toString())
  localStorage.setItem(SCROLL_POSITION_DATE_STORAGE_KEY, new Date().toString())
}

const InitialLoader: React.FC = () => {
  const { pathname } = useLocation()
  const api = useApiContext()
  const { useAsyncAction: usePageAsyncAction } = usePageContext()
  const { useAsyncAction: useMenuAsyncAction } = useMenuContext()
  const { useAsyncAction: useUserAsyncAction } = useUserContext()
  const { useAsyncAction: useCartAsyncAction } = useCartContext()
  const { useAsyncAction: useMarketAsyncAction } = useMarketContext()
  const {
    state: { siteSettings },
    useAsyncAction: useSiteSettingsAsyncAction,
  } = useSiteSettingsContext()

  const getPage = usePageAsyncAction("page", (identifier: string) =>
    api.contents.getContentByIdentifier(identifier).then(preloadResources)
  )
  const getMenus = useMenuAsyncAction("menus", api.menus.getMenus)
  const getCarts = useCartAsyncAction("carts", api.cart.getCarts)
  const getUser = useUserAsyncAction("user", api.users.getMe)
  const getSiteSettings = useSiteSettingsAsyncAction(
    "siteSettings",
    api.siteSettings.getSiteSettings
  )
  const getMarkets = useMarketAsyncAction(
    "marketOptions",
    api.markets.getMarkets
  )

  useEffect(() => {
    let scrollY: number | null = null
    const popStateListner = () => {
      isPopState = true
      scrollY = window.scrollY
    }

    window.addEventListener("popstate", popStateListner)

    let changed = false

    getPage(pathname).then(() => {
      if (!changed && isPopState) {
        const scrollPos = scrollPositions[scrollPositions.length - 1 + index]
        if (scrollPos) {
          window.scrollTo({
            top: scrollPos.position,
          })
        }
      }
      isPopState = false
    })

    return () => {
      const nextPath = window.location.pathname
      const latest = scrollPositions[scrollPositions.length - 1]
      if (!isPopState) {
        if (!latest || (latest && latest.pathname !== pathname)) {
          scrollPositions.push({
            pathname,
            position: window.scrollY,
          })
        } else {
          latest.position = window.scrollY
        }
      } else {
        const nextIndex = scrollPositions.length + index
        const currentIndex = nextIndex - 1
        const prevIndex = nextIndex - 2
        const nextItem = scrollPositions[nextIndex]
        const currentItem = scrollPositions[currentIndex]
        const prevItem = scrollPositions[prevIndex]

        if (index === 0) {
          console.log("backward")
          if (
            scrollPositions.length > 1 &&
            latest &&
            latest.pathname === pathname
          ) {
            latest.position = scrollY!
          } else {
            scrollPositions.push({
              pathname,
              position: scrollY!,
            })
          }

          index--
        } else {
          let backwardOrForward = false
          if (nextItem && nextItem.pathname === nextPath) {
            console.log("forward")
            index++
            backwardOrForward = true
          }
          if (prevItem && prevItem.pathname === nextPath) {
            console.log("backward")
            index--
            backwardOrForward = true
          }
          if (currentItem && backwardOrForward) {
            currentItem.position = scrollY!
          }
        }
      }
      saveScrollPositions()
      changed = true
      if (!isPopState) {
        window.scrollTo({
          top: 0,
        })
      }

      window.removeEventListener("popstate", popStateListner)
    }
  }, [pathname, getPage])

  useEffect(() => {
    Promise.all([getMenus(), getSiteSettings(), getCarts(), getMarkets()])
  }, [getMenus, getSiteSettings, getCarts, getMarkets])

  useEffect(() => {
    if (siteSettings?.data?.properties["resellerPortalDisabled"] === false) {
      getUser()
    }
  }, [getUser, siteSettings?.data?.properties])

  return null
}

export default InitialLoader
