import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import React from "react"

type SpinnerProps = {
  small?: boolean
  noDelay?: boolean
}

const createSvgSpinner = (width: number, height: number, color = "000") =>
  `data:image/svg+xml;utf8,${`<svg width="${width}px"  height="${height}px"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="background: none;"><g transform="rotate(0 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.4583333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(30 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(60 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.375s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(120 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.2916666666666667s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(150 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.25s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.20833333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(210 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(240 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.125s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(300 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="-0.041666666666666664s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(330 50 50)">
  <rect x="47" y="23" rx="0" ry="0" width="6" height="14" fill="#${color}">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="0.5s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
</g></svg>`
    .replace(/#/gi, "%23")
    .replace(/"/g, "'")
    .replace(/\n\s*/g, "")}`

const SMALL_BLACK_SPINNER = createSvgSpinner(32, 32, "000")
const SMALL_WHITE_SPINNER = createSvgSpinner(32, 32, "fff")

type SmallSpinnerProps = {
  light?: boolean
  centered?: boolean
  message?: React.ReactNode
  className?: string
}

const SmallSpinner: React.FC<SmallSpinnerProps> = ({
  light,
  centered,
  message,
  className,
}) => {
  const image = (
    <>
      <img
        className={className}
        alt=""
        src={(light && SMALL_WHITE_SPINNER) || SMALL_BLACK_SPINNER}
      />
      {message && <span>{message}</span>}
    </>
  )
  return (
    (centered && (
      <Box mt={1} display="flex" alignItems="center" flexDirection="column">
        {image}
      </Box>
    )) ||
    image
  )
}

const Spinner: React.FC<SpinnerProps> = ({ small }) => (
  <Box display="flex" flexDirection="column" alignItems="center" my="10%">
    <CircularProgress {...(small && { size: 20 })} />
  </Box>
)

export {
  SmallSpinner,
  createSvgSpinner,
  SMALL_BLACK_SPINNER,
  SMALL_WHITE_SPINNER,
}
export default Spinner
