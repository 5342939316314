import { CartResponse } from "./cart"
import { Address } from "./checkout"
import request from "./request"

type OrderProperties = {
  creatorName?: string
}

export type OrderResponseModel = {
  status?: string
  createdDate?: string
  orderGroupId?: string
  trackingNumber?: string
  firstName?: string
  lastName?: string
  deliveryAddress?: Address
  billingAddress?: Address
  deliveryMethodName?: string
  paymentMethodName?: string
  properties?: OrderProperties
} & CartResponse

export type OrdersResponseModel = {
  carts: OrderResponseModel[]
  total: number
}

export type OrderApi = {
  getOrder: (id: string) => Promise<OrderResponseModel>
  getOrders: (limit?: number, offset?: number) => Promise<OrdersResponseModel>
}

const orderApi: OrderApi = {
  getOrder: (id: string) => request.get<OrderResponseModel>(`/orders/${id}`),
  getOrders: (limit = 20, offset = 0) =>
    request.get<OrdersResponseModel>(`/orders?limit=${limit}&offset=${offset}`),
}

export default orderApi
