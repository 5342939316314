import {
  impersonatedUserId,
  IMPERSONATION_COOKIE,
  isImpersonating,
} from "@ignite/utils/impersonation"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import qs from "qs"

import EpiConfig from "../config"
import jwt from "../services/jwt"
import { isLocalHost } from "../utils/location"
import { ApiError } from "./index"
import { TokenResponse } from "./users"

export const BASE_URL = (window["BASE_URL"] || process.env.REACT_APP_API_ROOT)!

interface AxiosApiError extends AxiosError {
  response: AxiosResponse<ApiError>
}

const request = axios.create({
  baseURL: BASE_URL,
  withCredentials: BASE_URL.endsWith("apiary-mock.com/") ? false : true,
})

export const requestWithoutTokenUpdate = axios.create({
  baseURL: BASE_URL,
  withCredentials: BASE_URL.endsWith("apiary-mock.com/") ? false : true,
})

const requestInterceptor = async (
  useTokenUpdate: boolean,
  config: AxiosRequestConfig
) => {
  if (
    config.data &&
    config.headers["Content-Type"] === "application/x-www-form-urlencoded"
  ) {
    config.data = qs.stringify(config.data)
  }

  if (jwt.token) {
    if (useTokenUpdate && Date.now() > jwt.tokenExpires) {
      await jwt.updateToken()
    }
    config.headers.Authorization = `Bearer ${jwt.token}`
  }

  //TODO check error with @types
  config.headers["Accept-Language"] = window["LANGUAGE_CODE"] || "en"

  config.headers["X-Requested-With"] = "XMLHttpRequest" // Detecting XMLHttprequest from javascript, default disabled in axios

  if (isLocalHost() && isImpersonating()) {
    config.headers[IMPERSONATION_COOKIE] = impersonatedUserId()
  }

  return config
}

requestWithoutTokenUpdate.interceptors.request.use((config) => {
  return requestInterceptor(false, config)
})

request.interceptors.request.use((config) => {
  return requestInterceptor(true, config)
})

const responseInterceptorResponse = (response: AxiosResponse<any>) => {
  if (isTokenResponse(response.data)) {
    jwt.token = response.data.access_token
    jwt.tokenExpires = response.data.expires_in
    jwt.refreshToken = response.data.refresh_token
  }
  return response.data
}

const responseInterceptorError = async (error: any) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error)
  }

  if (isExpiredRefreshTokenError(error)) {
    jwt.clear() // We clear our tokens
    window.location.reload() // And reload the page to make sure user is logged out
    return Promise.reject(error.response.data.code)
  }

  if (!isApiError(error)) {
    return Promise.reject(
      EpiConfig.alwaysReturnApiErrors === "true"
        ? error.response.data
        : "NETWORK_ERROR"
    )
  }

  if (isExpiredTokenError(error) && !error.config["isRetryRequest"]) {
    if (!jwt.refreshToken) {
      return Promise.reject(error.response.data.code)
    }

    await jwt.updateToken()

    error.config["isRetryRequest"] = true
    return request(error.config)
  }
  return Promise.reject(error.response.data)
}

request.interceptors.response.use(
  (response: AxiosResponse) => {
    return responseInterceptorResponse(response)
  },
  async (error: any) => {
    return await responseInterceptorError(error)
  }
)

requestWithoutTokenUpdate.interceptors.response.use(
  (response: AxiosResponse) => {
    return responseInterceptorResponse(response)
  },
  async (error: any) => {
    return await responseInterceptorError(error)
  }
)

const isExpiredRefreshTokenError = (error: AxiosApiError) =>
  error.config &&
  error.response.status === 400 &&
  (error.response.data.code === "TOKEN_EXPIRED" ||
    error.response.data.code === "INVALID_TOKEN" ||
    (error.response.data.error === "invalid_grant" &&
      error.config.data.toLowerCase().includes("grant_type=refresh_token")))

const isTokenResponse = (arg: any): arg is TokenResponse =>
  arg.access_token !== undefined && arg.refresh_token !== undefined

const isApiError = (error: any): error is AxiosApiError =>
  error.response &&
  error.response.data &&
  error.response.data.code !== undefined

const isExpiredTokenError = (error: AxiosApiError) =>
  error.config &&
  error.response.status === 401 &&
  error.response.data.code === "NOT_AUTHORIZED"

export default request
